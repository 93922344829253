import React from 'react';

const ServicesBar = () => {
  return (
    <section className="services-bar">
      <ul className="services">
        <li>Renovations</li>
        <li>Repairs</li>
        <li>New Construction</li>
        <li>Remodelling</li>
        <li>Consulting</li>
      </ul>
    </section>
  );
};

export default ServicesBar;
