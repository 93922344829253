import React from 'react';

const PastJobsContent = () => {
  return (
    <section className="about-us">
      <div className="content">
        <h2>Past Jobs</h2>
        <p>
          Within our own network of contractors and trades, Credible Contractors
          has completed major projects with all of our trade and contracting
          partners and gone through the process of vetting and hiring
          contractors dozens of times.
        </p>
        <p>
          We have years of experience in all areas of construction and we can
          help you through the entire process from initial planning, hiring and
          contract right to completing your project.{' '}
        </p>

        <h2>Projects of all sizes</h2>
        <p>The size of your project isn’t what determines if we can help. </p>
        <p>
          Credible Contractors has completed hundreds of projects. From small
          handyman fix-ups and minor renovations to complete top-to-bottom
          renovations and multi-million dollar residential and commercial
          properties from the ground up, we’ve seen and done it all.
        </p>
      </div>

      <div className="section-image">
        <img src="images/about-us.jpg" alt="" />
      </div>
    </section>
  );
};

export default PastJobsContent;
