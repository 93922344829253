import React from 'react';

const Gallery = () => {
  return (
    <section className="gallery">
      <img src="/images/gallery-0001.jpg" alt="" />
      <img src="/images/gallery-0002.jpg" alt="" />
      <img src="/images/gallery-0003.jpg" alt="" />
      <img src="/images/gallery-0004.jpg" alt="" />
      <img src="/images/gallery-0005.jpg" alt="" />
      <img src="/images/gallery-0006.jpg" alt="" />
      <img src="/images/gallery-0007.jpg" alt="" />
      <img src="/images/gallery-0008.jpg" alt="" />
      <img src="/images/gallery-0009.jpg" alt="" />
      <img src="/images/gallery-0010.jpg" alt="" />
      <img src="/images/gallery-0011.jpg" alt="" />
      <img src="/images/gallery-0012.jpg" alt="" />
      <img src="/images/gallery-0013.jpg" alt="" />
      <img src="/images/gallery-0014.jpg" alt="" />
      <img src="/images/gallery-0015.jpg" alt="" />
      <img src="/images/gallery-0016.jpg" alt="" />
    </section>
  );
};

export default Gallery;
