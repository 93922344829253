import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <aside className="cta">
      <p>Quotes and project consultations are always FREE & FAST</p>
      <div className="cta-button inverse">
        <Link to="/quote">Get a free quote</Link>
      </div>
    </aside>
  );
};

export default CallToAction;
