import React from 'react';
import ServicesBar from './components/ServicesBar';
import ServicesList from './components/ServicesList';
import CallToAction from './components/CallToAction';

const Articles = () => {
  return (
    <>
      <ServicesBar />
      <main className="land-dev">
        <section className="about-us">
          <div className="content">
            <h2>Land Development Consulting</h2>
            <p>
              If you have a potential land development project (established or
              new, residential sub-division, apartment building, condo,
              commercial, industrial, mixed-use, church, retail, etc.) you're
              looking to get off the ground, please give us the opportunity to
              talk! We've been through it all (the good, the bad and the ugly),
              and can help you maneuver through whatever you need help with
              (Canada-wide).
            </p>

            <p>
              Our group has collectively been involved in various aspects of
              over 2,500 units/doors (over $100,000,000+ worth), from:
            </p>
            <ul className="land-development">
              <li>
                Initial Analysis / Pre-Development (to maximize the opportunity)
              </li>
              <li>Market / Feasibility Analysis</li>
              <li>Purchase or Sale Representation</li>
              <li>Regulatory, Planning & Rezoning</li>
              <li>Architectural Design</li>
              <li>
                All Types of Engineering (environmental, civil, mechanical,
                etc.)
              </li>
              <li>
                Value Engineering Analysis / Tendering (to maximize your hard
                earned dollar!)
              </li>
              <li>
                Project Management (from start to finish, or anywhere in
                between)
              </li>
              <li>
                Construction Management (from start to finish, or anywhere in
                between)
              </li>
              <li>
                Sales, Marketing & Leasing (if you plan on selling the end
                product, such as a condo project, apartment building, strip
                mall, industrial park, etc.)
              </li>
              <li>
                Joint Venture Partnership Arrangements (if needed, finding the
                right partner/s to help you get your project off the group)
              </li>
              <li>
                Legal / Contractual Representation (legal agreements, preparing
                an Offering Memorandum (OM), etc.)
              </li>
              <li>
                Financial Consulting (funding your project, mortgage consulting,
                CHMC consulting, Private Equity, Offering Memorandum (and who to
                take it to market)), etc.)
              </li>
              <li>Property Management & More.....</li>
            </ul>

            <p>
              Please leave your information below, and we look forward to
              talking:
            </p>

            <form name="land-development" method="post">
              <input type="hidden" name="form-name" value="quote" />
              <h2>Your Details</h2>
              <div className="form-group">
                <label for="first-name">
                  First Name <span className="required">*</span>
                </label>
                <input type="text" name="first-name" required />
              </div>

              <div className="form-group">
                <label for="last-name">Last Name</label>
                <input type="text" name="last-name" />
              </div>

              <div className="form-group">
                <label for="email">
                  Email <span className="required">*</span>
                </label>
                <input type="email" name="email" required />
              </div>

              <div className="form-group">
                <label for="phone">Phone</label>
                <input type="tel" name="phone" />
              </div>

              <div className="required-message">
                <p>
                  <span className="required">*</span> required field
                </p>
              </div>
              <div className="form-group">
                <h2>
                  Project Information <span className="required">*</span>
                </h2>
                <p>
                  Please tell us a little about your land development project
                </p>
                <textarea name="project-information" required></textarea>
              </div>

              <div className="form-group">
                <input type="submit" value="Send" />
              </div>
            </form>
          </div>

          <div className="section-image">
            <img src="images/land-development.jpg" alt="" />
          </div>
        </section>
        <ServicesList />
        <CallToAction />
      </main>
    </>
  );
};

export default Articles;
