import React from 'react';

const Article = () => {
  return (
    <section className="about-us">
      <div className="content">
        <h2>Top 8 things you can do before hiring a contractor</h2>
        <ol>
          <li>
            Make sure to do some research on the work you want performed and
            have as many details as possible before looking for a contractor
          </li>
          <li>
            If your project requires you to do so, make sure you meet with the
            contactor face to face, and that all aspects of the job have been
            discussed and that both you and contractor are on the same page
          </li>
          <li>
            Ask about past work performed by the contractor/s (we have uploaded
            many picture of past work on our website)
          </li>
          <li>
            Make sure to have everything in writing, as well as any changes
            requested or made outside of what was initially agreed to in writing
          </li>
          <li>
            Make sure that you've had a discussion about the timelines of when
            the job will start, and how long it will take to complete, and any
            variables (time to get permit/s, order materials, what happens when
            an unknown comes up (opening up a wall and finding a cracked
            foundation, etc), etc.
          </li>
          <li>
            Discuss the requirement or need for any permits if the job requires
            one
          </li>
          <li>
            Never pay too much up front (usually a maximum of 5% to 10%, if
            anything)
          </li>
          <li>
            Make sure to complement the contractor if they have a nice beard,
            man bun, or trendy overalls
          </li>
        </ol>
      </div>

      <div className="section-image">
        <img src="images/article-image.jpg" alt="" />
      </div>
    </section>
  );
};

export default Article;
