import React from 'react';
import ServicesBar from './components/ServicesBar';

const Quote = () => {
  return (
    <>
      <ServicesBar />
      <main>
        <section className="form main-section">
          <div className="content">
            <p>
              Tell us about yourself and your project and we'll put together a
              comprehensive quote.
            </p>
            <form name="quote" method="post">
              <input type="hidden" name="form-name" value="quote" />

              <h2>Your Details</h2>
              <div className="form-group">
                <label for="first-name">
                  First Name <span className="required">*</span>
                </label>
                <input type="text" name="first-name" required />
              </div>

              <div className="form-group">
                <label for="last-name">Last Name</label>
                <input type="text" name="last-name" />
              </div>

              <div className="form-group">
                <label for="email">
                  Email <span className="required">*</span>
                </label>
                <input type="email" name="email" required />
              </div>

              <div className="form-group">
                <label for="phone">Phone</label>
                <input type="tel" name="phone" />
              </div>

              <h2>Project Details</h2>
              <div className="form-group">
                <label for="property-address">Property Address</label>
                <input type="text" name="property-address" />
              </div>

              <div className="form-group">
                <label for="project-type">
                  Project Type <span className="required">*</span>
                </label>
                <select name="project-type" required>
                  <option value="renovation">Renovation</option>
                  <option value="repair">Repair</option>
                  <option value="remodel">Remodel</option>
                  <option value="new-construction">New Construction</option>
                  <option value="consultation">Consultation</option>
                  <option value="renovation">Land Development</option>
                </select>
              </div>

              <div className="form-group">
                <label for="timeline">
                  Timeline <span className="required">*</span>
                </label>
                <input type="text" name="timeline" />
              </div>

              <div className="form-group">
                <label for="budget">Budget Range</label>
                <input type="text" name="budget" />
              </div>

              <div className="form-group">
                <h2>
                  Project Description <span className="required">*</span>
                </h2>
                <textarea name="project-description" required></textarea>
              </div>

              <div className="required-message">
                <p>
                  <span className="required">*</span> required field
                </p>
              </div>
              <div className="form-group">
                <input type="submit" value="Send" />
              </div>
            </form>
          </div>

          <div className="section-image">
            <img src="images/clipboard-contractor.jpg" alt="" />
          </div>
        </section>
      </main>
    </>
  );
};

export default Quote;
