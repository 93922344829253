import React from 'react';
// import ReactDOM from 'react-dom';
import { render } from 'react-snapshot';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.css';
import Home from './Home';
import Services from './Services';
import PastJobs from './PastJobs';
import Quote from './Quote';
import Articles from './Articles';
import LandDevelopment from './LandDevelopment';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';

render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path="/services">
          <Services />
        </Route>

        <Route path="/past-jobs">
          <PastJobs />
        </Route>

        <Route path="/quote">
          <Quote />
        </Route>

        <Route path="/articles">
          <Articles />
        </Route>

        <Route path="/land-development">
          <LandDevelopment />
        </Route>

        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
