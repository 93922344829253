import Hero from './components/Hero';
import ServicesBar from './components/ServicesBar';
import ServicesList from './components/ServicesList';
import AboutUs from './components/AboutUs';
import RecentWork from './components/RecentWork';
import CallToAction from './components/CallToAction';

function Home() {
  return (
    <>
      <Hero />

      <ServicesBar />
      <main>
        <ServicesList />
        <CallToAction />

        <AboutUs />
        <RecentWork />

        <CallToAction />
      </main>
    </>
  );
}

export default Home;
