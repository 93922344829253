import React from 'react';

const ServicesList = () => {
  return (
    <>
      <section id="services" className="all-services">
        <section className="services-list">
          <h2>Construction Services</h2>
          <ul className="butter">
            <li>Basement Renovations</li>
            <li>Bathroom Renovations</li>
            <li>Foundation Repair</li>
            <li>Garage Construction</li>
            <li>Home Additions</li>
            <li>Kitchen Renovations</li>
          </ul>
          <ul>
            <li>A/V Systems</li>
            <li>Concrete</li>
            <li>Countertops</li>
            <li>Junk Removal</li>
            <li>Decks</li>
            <li>Demolition</li>
            <li>Eavestroughs</li>
            <li>Electrical</li>
            <li>Exterior Siding</li>
            <li>Fencing</li>
            <li>Finishing work</li>
            <li>Flooring</li>
            <li>Framing</li>
            <li>HVAC</li>
            <li>Landscaping</li>
            <li>Painting</li>
            <li>Plumbing</li>
            <li>Roofing</li>
            <li>Security Systems</li>
            <li>Stone Work</li>
            <li>Stucco</li>
            <li>Tiling</li>
            <li>Windows & Doors</li>
          </ul>
        </section>

        <section className="services-list">
          <h2>Consulting Services</h2>
          <ul>
            <li>Agent Referral</li>
            <li>Architectural</li>
            <li>Design</li>
            <li>Engineering</li>
            <li>House Flipping</li>
            <li>Inspection</li>
            <li>Insurance Claims</li>
            <li>Land Development</li>
            <li>Project Development</li>
            <li>Project Management</li>
            <li>Renovations</li>
            <li>Rezoning</li>
          </ul>
        </section>
      </section>
    </>
  );
};

export default ServicesList;
