import React from 'react';
import ServicesBar from './components/ServicesBar';
import ServicesList from './components/ServicesList';
import CallToAction from './components/CallToAction';
import RecentWork from './components/RecentWork';

const Services = () => {
  return (
    <>
      <ServicesBar />
      <main>
        <ServicesList />
        <CallToAction />

        <RecentWork />

        <CallToAction />
      </main>
    </>
  );
};

export default Services;
