import React from 'react';

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="content">
        <h2>about us</h2>
        <p>
          Credible Contractors is a contracting and renovations company. Tell us
          about your renovation or construction project and we will get you fast
          quotes from reliable contractors in our network to complete the work.
        </p>
        <p>
          We've all heard the stories about working with contractors: being over
          billed, getting quotes that vary widely for the same job, bait and
          switch, and jobs going way over on the timeline. And let's not forget
          the demands for payment on an unfinished job or, worse yet, being
          ghosted by a contractor after paying a large deposit. Finding a
          contractor can be a daunting task, especially if you don't know a lot
          about the type of work you want completed.
        </p>
        <p>
          <em>Credible Contractors is here to help.</em>
        </p>
        <p>
          By guiding you through the process and providing access to our network
          of contractors built up over 30 years in the real estate business, we
          can help you hire reliable contractors so you can get your renovation
          and construction projects done without worry or stress.
        </p>

        <aside className="experience-numbers">
          <span>200+ new build projects</span>
          <span>500+ renovation projects</span>
          <span>500+ property portfolio</span>
        </aside>
      </div>

      <div className="section-image">
        <img src="images/about-us.jpg" alt="" />
      </div>
    </section>
  );
};

export default AboutUs;
