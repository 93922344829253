import ServicesBar from './components/ServicesBar';
import ServicesList from './components/ServicesList';
import CallToAction from './components/CallToAction';
import PastJobsContent from './components/PastJobsContent';
import Gallery from './components/Gallery';

function App() {
  return (
    <>
      <ServicesBar />
      <main>
        <PastJobsContent />
        <Gallery />
        <ServicesList />
        <CallToAction />
      </main>
    </>
  );
}

export default App;
