import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="hero">
      <img
        src="images/contractor-saw.jpg"
        alt="Contractor cutting baseboards with a radial saw"
      />
      <div className="overlay-content">
        <p>
          We help you hire reliable contractors so you can get your renovation
          and construction projects done without worry or stress.
        </p>
        <div className="cta-button">
          <Link to="/quote">Get a free quote</Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
