import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src="images/logo.png" alt="Credible Contractors Logo" />
        </Link>
      </div>
      <div className="stack">
        <div className="phone-number">
          <a href="tel:2042976388">204-297-6388</a>
        </div>
        <div className="cta-button">
          <Link to="/quote">Get a free quote</Link>
        </div>
      </div>
      <nav>
        <ul>
          <li className="menu-link">
            <Link to="/services">Services</Link>
          </li>
          <li className="menu-link">
            <Link to="/past-jobs">Past jobs</Link>
          </li>
          <li className="menu-link no-break">
            <Link to="/land-development">Land Development</Link>
          </li>

          <li className="menu-link">
            <Link to="/articles">Articles</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
