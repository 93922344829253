import React from 'react';
import { Link } from 'react-router-dom';

const RecentWork = () => {
  return (
    <section className="recent-work">
      <h2>recent work</h2>
      <div className="gallery">
        <img src="images/recent-work-1.jpg" alt="" />
        <img src="images/recent-work-2.jpg" alt="" />
        <img src="images/recent-work-3.jpg" alt="" />
        <img src="images/recent-work-4.jpg" alt="" />
      </div>
      <div className="see-more">
        <Link to="/past-jobs">see more &gt;&gt;</Link>
      </div>
    </section>
  );
};

export default RecentWork;
